<template>
  <page>
    <template #navigation>
      <div class="flex -mx-1">
        <action-icon
          class="mx-1"
          variant="primary"
          face="mdiPencilPlus"
          small
          @click="onAddUser"
          v-if="isAdmin"
        />
        <div class="flex-1 mx-1">
          <field
            compact
            :placeholder="$t('general.search')"
            small
            type="search"
            v-model="search"
          />
        </div>
      </div>
    </template>
    <div class="flex flex-row flex-wrap -mx-1">
      <div
        class="w-full md:w-1/2 lg:w-1/3 px-1 mt-4"
        v-for="member in members"
        :key="member._id"
      >
        <div
          class="
            w-24
            h-24
            lg:w-40 lg:h-40
            rounded-full
            border border-gray-200
            mx-auto
            bg-white
            z-10
            relative
            bg-center bg-no-repeat bg-cover
          "
          :style="{ backgroundImage: `url(${getAvatarUrl(member)}` }"
        >
          <div
            class="
              bg-white
              rounded-full
              absolute
              -bottom-4
              left-1/2
              -ml-4
              text-4xl text-yellow-500
              flex
              items-center
              justify-center
            "
            v-if="member.role === 'ADMIN'"
            title="Administrator"
          >
            <icon face="mdiStarCircle" />
          </div>
        </div>
        <div
          class="
            rounded
            bg-white
            -mt-12
            lg:-mt-20
            pt-12
            lg:pt-20
            border border-gray-200
            relative
            z-0
          "
        >
          <chip
            v-if="member._id === user._id"
            variant="success"
            size="xs"
            class="absolute top-1 right-1"
            >{{ $t('users.itsYou') }}</chip
          >
          <div class="py-4">
            <div
              class="px-8 py-1 text-center"
              v-for="detail in getDetails(member)"
              :key="detail.id"
            >
              <label class="text-xs text-gray-400">{{
                $t(detail.label)
              }}</label>
              <p
                class="
                  font-bold
                  -mt-1
                  w-full
                  overflow-hidden overflow-ellipsis
                  whitespace-nowrap
                "
              >
                {{ detail.value }}
              </p>
            </div>
          </div>
          <div
            class="p-4 flex flex-row justify-center align-center -mx-1"
            v-if="isAdmin"
          >
            <action-icon
              class="mx-1"
              variant="danger"
              @click="onDeleteUser(member)"
              :disabled="isBusy || member._id === user._id"
              face="mdiDeleteForeverOutline"
            />
            <action-icon
              class="mx-1"
              variant="primary"
              @click="onEditUser(member)"
              face="mdiPencil"
            />
            <action-icon
              v-if="isAdmin"
              :disabled="member._id === user._id"
              class="mx-1"
              variant="outline"
              @click="onImpersonateUser(member)"
              face="mdiAccountEdit"
            />
          </div>
        </div>
      </div>
    </div>
    <modal
      v-if="currentUser"
      @close="currentUser = null"
      :title="$t('actions.editName', currentUser)"
    >
      <form @submit.prevent="onSaveUser">
        <field
          name="name"
          i18n="profile.name"
          v-model="currentUser.name"
          @changed="currentUser.clear('name')"
          :error="currentUser.i18n('name')"
        />
        <field
          name="email"
          i18n="profile.email"
          v-model="currentUser.email"
          type="email"
          @changed="currentUser.clear('email')"
          :error="currentUser.i18n('email')"
        />
        <field
          name="phone"
          i18n="profile.phone"
          v-model="currentUser.phone"
          type="tel"
          @changed="currentUser.clear('phone')"
          :error="currentUser.i18n('phone')"
        />
        <selector
          name="roles"
          i18n="profile.role"
          v-model="currentUser.role"
          :options="roles"
          :disabled="isBusy || isCurrentUser"
        />
        <field
          :i18n="
            currentUser._id === '#add'
              ? 'profile.password'
              : 'profile.changePassword'
          "
          name="password"
          v-model="currentUser.password"
          autocomplete="new-password"
          type="password"
          @changed="currentUser.clear('password')"
          :error="currentUser.i18n('password')"
        />
        <div class="flex justify-end mt-4">
          <action
            variant="link"
            @click="currentUser = null"
            :disabled="isBusy"
            >{{ $t('actions.cancel') }}</action
          >
          <action
            variant="primary"
            type="submit"
            :disabled="isBusy"
            :loading="isBusy"
            >{{ $t('actions.save') }}</action
          >
        </div>
      </form>
    </modal>
    <modal
      v-if="impersonate"
      @close="hideImpersonating"
      :title="$t('impersonate.title', impersonate)"
    >
      <p class="text-sm text-gray-700">
        {{ $t('impersonate.text', impersonate) }}
      </p>
      <form @submit.prevent="requestImpersonateUser">
        <field
          class="mt-4"
          i18n="impersonate.password"
          type="password"
          autocomplete="current_password"
          v-model="safetyPassword"
          :disabled="isImpersonating"
        />
        <div class="flex items-center justify-end">
          <action
            type="button"
            variant="link"
            @click="hideImpersonating"
            :disabled="isImpersonating"
            >{{ $t('actions.cancel') }}</action
          >
          <action
            type="submit"
            variant="primary"
            :disabled="isImpersonating || safetyPassword.length < 6"
            :loading="isImpersonating"
            >{{ $t('impersonate.button', impersonate) }}</action
          >
        </div>
      </form>
    </modal>
  </page>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Form, storage } from '@/services'

export default {
  computed: {
    ...mapState({
      loaded: (state) => state.loaded,
      isBusy: (state) => state.users?.loading || state.users?.updating,
      users: (state) => state.users,
      user: (state) => state.user
    }),
    members() {
      const _search = this.search.trim().toLowerCase()
      if (!_search) {
        return this.users.items
      }
      return this.users.items.filter(
        (member) =>
          member.name.toLowerCase().includes(_search) ||
          member.email.toLowerCase().includes(_search) ||
          member.phone.includes(_search)
      )
    },
    isCurrentUser() {
      if (!this.currentUser) {
        return false
      }
      return this.currentUser._id === this.user?._id
    },
    isAdmin() {
      return this.user?.role === 'ADMIN'
    },
    roles() {
      return [
        { id: 'admin', value: 'ADMIN', label: this.$t('roles.ADMIN') },
        { id: 'agent', value: 'AGENT', label: this.$t('roles.AGENT') }
      ]
    },
    noCache() {
      return `ts-${Math.floor(10000000 * Math.random())}`
    }
  },
  data() {
    return {
      search: '',
      currentUser: null,
      impersonate: null,
      safetyPassword: '',
      isImpersonating: false
    }
  },
  methods: {
    ...mapActions(['users/create', 'users/update', 'users/destroy']),
    _err(field) {
      if (!this.currentUser) {
        return false
      }
      const e = this.currentUser.errors[field]
      return e ? this.$t(`validation.${e}`) : null
    },
    getAvatarUrl(member) {
      return `${process.env.VUE_APP_SERVER_URL}/media/avatar/${member._id}.png?_=${this.noCache}`
    },
    getDetails(member) {
      return [
        { id: 'name', label: 'profile.name', value: member.name || '-' },
        { id: 'email', label: 'profile.email', value: member.email || '-' },
        { id: 'phone', label: 'profile.phone', value: member.phone || '-' }
      ]
    },

    onAddUser() {
      if (!this.isAdmin) {
        return false
      }
      this.currentUser = new Form(
        {
          _id: '#add',
          name: '',
          email: '',
          phone: '',
          role: 'AGENT',
          password: ''
        },
        {
          name: ['required'],
          email: ['required', 'email'],
          phone: ['required'],
          password: ['required']
        }
      )
    },
    onEditUser(member) {
      if (!this.isAdmin) {
        return false
      }
      this.currentUser = new Form(
        {
          ...JSON.parse(JSON.stringify(member)),
          password: ''
        },
        {
          name: ['required'],
          email: ['required', 'email'],
          phone: ['required']
        }
      )
    },
    async onSaveUser() {
      if (!this.isAdmin || !this.currentUser) {
        this.currentUser = null
        return false
      }

      if (!this.currentUser.validate()) {
        return false
      }

      const { _id, name, email, phone, password, role } = this.currentUser.data
      try {
        if (_id === '#add') {
          await this['users/create']({
            name,
            email,
            phone,
            password,
            role
          })
        } else {
          await this['users/update']({
            _id,
            name,
            email,
            phone,
            password,
            role
          })
        }
      } catch (err) {
        console.error('Error while saving user', err)
      } finally {
        this.currentUser = null
      }
    },
    onDeleteUser() {
      this.$toast('danger', 'notYetImplemented')
    },
    hideImpersonating() {
      if (this.isImpersonating) {
        return false
      }
      this.impersonate = null
      this.safetyPassword = ''
    },
    async onImpersonateUser(member) {
      if (!this.isAdmin) {
        return false
      }
      this.impersonate = member
      this.isImpersonating = false
      this.safetyPassword = ''
    },
    async requestImpersonateUser() {
      if (!this.isAdmin) {
        this.impersonate = false
        return
      }
      if (!this.impersonate) {
        return
      }
      this.isImpersonating = true
      try {
        const response = await this.$http.post('/auth/pose', {
          poseId: this.impersonate._id,
          password: this.safetyPassword
        })
        storage.token = response.data.token
        const profile = await this.$http.get('/auth/profile', { refresh: true })
        this.$store.dispatch('login', profile.data.data)
        this.$router.push('/')
      } catch (e) {
        console.error(`* Error while posing as ${this.impersonate}`, e)
        this.$toast('danger', 'errors.impersonating')
      } finally {
        this.isImpersonating = false
        this.impersonate = null
      }
    }
  }
}
</script>
